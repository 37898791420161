import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { setTitle } from 'utils/head';
import { Box } from '@mui/material';
const SchoolManagement = () => {
    const { t } = useTranslation('schoolManagement');
    useEffect(() => {
        setTitle(t('title'));
    }, [t]);
    return (_jsx(Box, { children: _jsxs("h1", { children: ["Todo: ", t('title')] }) }));
};
export default SchoolManagement;
