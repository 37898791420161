import { floor } from 'lodash-es';
/**
 * Calculates how many percent is a `value` of `max`.
 * @param value Current value.
 * @param max Maximal value.
 * @param precision Precision for results smaller than 1.
 * @returns A value in range: `0-100`.
 */
export const calcPercentage = (value, max, precision = 2) => {
    if (value === 0 || max === 0) {
        return 0;
    }
    const maxPercent = 100;
    const percent = (value / max) * maxPercent;
    return percent > 1 ? Math.floor(percent) : floor(percent, precision);
};
